<template>
  <div class="main-body">
    <div class="page-attention">
      <p>服务名称只做显示使用</p>
    </div>
    <a-divider />
    <a-form :model="formState" autocomplete="off" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSubmit">
      <a-form-item label="服务名称" name="service_name" :rules="[{ required: true, message: '请输入服务名称!' }]">
        <a-input class="half-input" v-model:value="formState.service_name" placeholder="请输入服务名称" />
      </a-form-item>
      <a-form-item label="图标" name="service_image">
        <CsImage v-model:imgOne="formState.brand_image" />
      </a-form-item>
      <a-form-item label="描述" name="service_desc">
        <a-textarea class="half-input" v-model:value="formState.service_desc" placeholder="请输入分类描述" allow-clear />
      </a-form-item>
      <a-form-item label="排序" name="sort" extra="数字越大，排序越靠前，数字重复，则最新添加的靠前。">
        <a-input-number class="medium-input" v-model:value="formState.sort" :min="0" :max="1000000" />
      </a-form-item>
      <a-form-item class="form-submit-layout">
        <a-button type="primary" html-type="submit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive } from "vue";
import CsImage from "@/components/UpImage";

export default defineComponent({
  components: {
    CsImage,
  },
  setup() {
    const formState = reactive({
      service_name: "",
      service_image: "",
      service_desc: "",
      sort: "0",
    });

    const onSubmit = () => {};

    return {
      labelCol: {
        style: {
          width: "100px",
        },
      },
      labelColSecond: {
        style: {
          width: "44px",
        },
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>